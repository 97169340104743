import {
   ArrowMarkerState,
   LineMarkerState,
   MarkerAreaState,
   RectangleMarkerState,
} from 'markerjs2';
import {
   CommonMarkupState,
   ImageInfo,
   isMarkerjsMarkersState,
   isNodeMarkupMarkersState,
   NodeMarkupCircleMarker,
   NodeMarkupLineMarker,
   NodeMarkupRectangleMarker,
} from './ImageInfo';
import { toCropAreaState } from './toCropAreaState';
import { CropAreaState } from 'cropro';
import { getDefaultStrokeWidth } from './getDefaultStrokeWidth';
import { round } from 'lodash';

const colorValues = {
   red: '#C1280B',
   orange: '#FF9024',
   yellow: '#F3E00E',
   green: '#16DC81',
   lightBlue: '#1BB1E9',
   blue: '#2343E8',
   darkBlue: '#2343E8',
   violet: '#DC54B7',
   pink: '#DC54B7',
   black: '#000000',
};

export function toMarkerAreaState(imageInfo: ImageInfo): MarkerAreaState | null {
   const markers = imageInfo.markupState?.annotations;
   if (!markers) {
      return null;
   }

   if (isNodeMarkupMarkersState(markers)) {
      const cropAreaState = toCropAreaState(imageInfo);

      return {
         height: cropAreaState?.cropRect.height || imageInfo.height,
         width: cropAreaState?.cropRect.width || imageInfo.width,
         markers: markers.data
            .map(nodeMarkupMarker => {
               if (nodeMarkupMarker.shape === 'circle') {
                  return fromNodeMarkupCircle(nodeMarkupMarker, cropAreaState);
               }
               if (nodeMarkupMarker.shape === 'rectangle') {
                  return fromNodeMarkupRectangle(nodeMarkupMarker, cropAreaState);
               }
               if (nodeMarkupMarker.shape === 'line') {
                  return fromNodeMarkupLine(nodeMarkupMarker, cropAreaState);
               }
               if (nodeMarkupMarker.shape === 'arrow') {
                  return fromNodeMarkupArrow(nodeMarkupMarker, cropAreaState);
               }
               if (nodeMarkupMarker.shape === 'gap') {
                  return fromNodeMarkupGap(nodeMarkupMarker, cropAreaState);
               }
            })
            // Filter just in case there's an unknown shape type from bad data that would have
            // broken the markers editor.
            .filter(marker => marker),
      };
   } else if (isMarkerjsMarkersState(markers)) {
      return fromMarkerjsState(markers);
   }

   // Markers type isn't implemented!
   return null;
}

function fromMarkerjsState(state: CommonMarkupState<MarkerAreaState>): MarkerAreaState {
   const defaultStrokeWidth = getDefaultStrokeWidth();
   return {
      ...state.data,
      markers: state.data.markers.map(marker => {
         // The stroke width is saved relative to the size of the image in the editor. We
         // recalculate the stroke width in case the editor now is being opened on a different
         // screen size.
         if (marker['strokeWidthRatioToDefault']) {
            marker['strokeWidth'] = round(
               defaultStrokeWidth * marker['strokeWidthRatioToDefault'],
               1
            );
         } else if (marker['strokeWidth']) {
            // Fallback to the default if we can't determine which stroke width option was chosen
            marker['strokeWidth'] = defaultStrokeWidth;
         }
         return marker;
      }),
   };
}

function getCommonRectangleState() {
   return {
      strokeWidth: getDefaultStrokeWidth(),
      fillColor: 'transparent',
      opacity: 1,
      rotationAngle: 0,
      state: 'select' as const,
      notes: undefined,
      strokeDasharray: '',
      containerTransformMatrix: {
         a: 1,
         b: 0,
         c: 0,
         d: 1,
         e: 0,
         f: 0,
      },
      visualTransformMatrix: {
         a: 1,
         b: 0,
         c: 0,
         d: 1,
         e: 0,
         f: 0,
      },
   };
}

function fromNodeMarkupCircle(
   nodeMarkupCircle: NodeMarkupCircleMarker,
   cropAreaState: CropAreaState | null
): RectangleMarkerState {
   const cropOffset = getCropOffset(cropAreaState);

   return {
      ...getCommonRectangleState(),
      typeName: 'EllipseFrameMarker',
      height: nodeMarkupCircle.radius * 2,
      width: nodeMarkupCircle.radius * 2,
      left: nodeMarkupCircle.x - cropOffset.x - nodeMarkupCircle.radius,
      top: nodeMarkupCircle.y - cropOffset.y - nodeMarkupCircle.radius,
      strokeColor: colorValues[nodeMarkupCircle.color],
   };
}

function fromNodeMarkupRectangle(
   nodeMarkupRectangle: NodeMarkupRectangleMarker,
   cropAreaState: CropAreaState | null
): RectangleMarkerState {
   const cropOffset = getCropOffset(cropAreaState);

   return {
      ...getCommonRectangleState(),
      typeName: 'FrameMarker',
      height: nodeMarkupRectangle.height,
      width: nodeMarkupRectangle.width,
      left: nodeMarkupRectangle.x - cropOffset.x,
      top: nodeMarkupRectangle.y - cropOffset.y,
      strokeColor: colorValues[nodeMarkupRectangle.color],
   };
}

function fromNodeMarkupLine(
   nodeMarkupLine: NodeMarkupLineMarker,
   cropAreaState: CropAreaState | null
): LineMarkerState {
   return {
      ...getBaseLineStateFromNodeMarkup(nodeMarkupLine, cropAreaState),
      typeName: 'LineMarker',
   };
}

function fromNodeMarkupArrow(
   nodeMarkupArrow: NodeMarkupLineMarker,
   cropAreaState: CropAreaState | null
): ArrowMarkerState {
   return {
      ...getBaseLineStateFromNodeMarkup(nodeMarkupArrow, cropAreaState),
      typeName: 'ArrowMarker',
      arrowType: 'end',
   };
}

function fromNodeMarkupGap(
   nodeMarkupGap: NodeMarkupLineMarker,
   cropAreaState: CropAreaState | null
): LineMarkerState {
   return {
      ...getBaseLineStateFromNodeMarkup(nodeMarkupGap, cropAreaState),
      typeName: 'MeasurementMarker',
   };
}

function getBaseLineStateFromNodeMarkup(
   nodeMarkupLine: NodeMarkupLineMarker,
   cropAreaState: CropAreaState
): Omit<LineMarkerState, 'typeName'> {
   const cropOffset = getCropOffset(cropAreaState);

   return {
      x1: nodeMarkupLine.xStart - cropOffset.x,
      x2: nodeMarkupLine.xEnd - cropOffset.x,
      y1: nodeMarkupLine.yStart - cropOffset.y,
      y2: nodeMarkupLine.yEnd - cropOffset.y,
      strokeColor: colorValues[nodeMarkupLine.color],
      strokeWidth: getDefaultStrokeWidth(),
      notes: undefined,
      state: 'select',
      strokeDasharray: '',
   };
}

function getCropOffset(cropAreaState: CropAreaState | null) {
   let x = 0;
   let y = 0;
   if (cropAreaState) {
      // CropArea always has a 20px offset on x and y, but MarkerArea doesn't
      x = cropAreaState.cropRect.x - 20;
      y = cropAreaState.cropRect.y - 20;
   }

   return { x, y };
}
