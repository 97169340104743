import { SvgHelper } from 'markerjs2';

interface Stamp {
   readonly name: string;
   readonly svgPath: string;
   readonly icon: string;
   path(strokeColor: string, strokeWidth: number): SVGElement;
}

class BaseStamp implements Stamp {
   constructor(
      public readonly name: string,
      public readonly svgPath: string,
      private readonly viewBox: string = '0 0 16 16'
   ) {}

   get icon(): string {
      return `<svg viewBox="${this.viewBox}"><path d="${this.svgPath}"/></svg>`;
   }

   path(strokeColor: string, strokeWidth: number): SVGElement {
      return SvgHelper.createPath(this.svgPath, [
         ['fill', 'transparent'],
         ['stroke', strokeColor],
         ['stroke-width', strokeWidth.toString()],
      ]);
   }
}

const stamps: Record<string, Stamp> = {
   checkMark: new BaseStamp(
      'checkMark',
      'M14.7531 3.21875C15.0719 3.5125 15.0719 3.9875 14.7531 4.25313L6.50313 12.5031C6.2375 12.8219 5.7625 12.8219 5.46875 12.5031L1.21966 8.25313C0.926781 7.9875 0.926781 7.5125 1.21966 7.21875C1.5125 6.92813 1.9875 6.92813 2.28031 7.21875L6 10.9406L13.7188 3.21875C14.0125 2.92688 14.4875 2.92688 14.7531 3.21875Z'
   ),
   xMark: new BaseStamp(
      'xMark',
      'M12.7531 11.7188C13.0459 12.0115 13.0459 12.4866 12.7531 12.7794C12.4603 13.0722 11.9853 13.0722 11.6925 12.7794L8 9.05938L4.28125 12.7781C3.98847 13.0709 3.51344 13.0709 3.22062 12.7781C2.92781 12.4853 2.92784 12.0103 3.22062 11.7175L6.94062 8L3.21959 4.25313C2.92681 3.96035 2.92681 3.48531 3.21959 3.1925C3.51237 2.89969 3.98741 2.89972 4.28022 3.1925L8 6.94063L11.7188 3.22188C12.0115 2.9291 12.4866 2.9291 12.7794 3.22188C13.0722 3.51466 13.0722 3.98969 12.7794 4.2825L9.05937 8L12.7531 11.7188Z'
   ),
   stampMark: new BaseStamp(
      'stampMark',
      'M312 201.8c0-17.4 9.2-33.2 19.9-47C344.5 138.5 352 118.1 352 96c0-53-43-96-96-96s-96 43-96 96c0 22.1 7.5 42.5 20.1 58.8c10.7 13.8 19.9 29.6 19.9 47c0 29.9-24.3 54.2-54.2 54.2L112 256C50.1 256 0 306.1 0 368c0 20.9 13.4 38.7 32 45.3L32 464c0 26.5 21.5 48 48 48l352 0c26.5 0 48-21.5 48-48l0-50.7c18.6-6.6 32-24.4 32-45.3c0-61.9-50.1-112-112-112l-33.8 0c-29.9 0-54.2-24.3-54.2-54.2zM416 416l0 32L96 448l0-32 320 0z',
      '0 0 512 512'
   ),
};

export default stamps;
