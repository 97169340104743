import { CSRF } from 'Shared/csrf';
import {
   CommonMarkupState,
   ImageInfo,
   isCroproEditsState,
   isMarkerjsMarkersState,
} from './ImageInfo';

export async function uploadEditedImage(
   dataUrl: string,
   state: CommonMarkupState<any>,
   previousImageInfo: ImageInfo,
   mediaItemFilterName: string
) {
   const blob = await (await fetch(dataUrl)).blob();
   // Ex: image/png
   const mime = blob.type;
   const extension = mime.split('/')[1];
   const filename =
      previousImageInfo.exif?.FileName || `edited-image-${previousImageInfo.image.id}.${extension}`;

   const formData = new FormData();
   formData.append('file', blob, filename);

   let edits = previousImageInfo.markupState?.edits || null;
   let annotations = previousImageInfo.markupState?.annotations || null;
   if (isCroproEditsState(state)) {
      edits = state;
      // We blow away any annotations when the image is edited
      annotations = null;
   } else if (isMarkerjsMarkersState(state)) {
      annotations = state.data.markers.length > 0 ? state : null;
      // Markup changes shouldn't affect the filter state
      mediaItemFilterName = null;
   }

   formData.append(
      'json',
      JSON.stringify({
         imageid: previousImageInfo.image.id,
         edits,
         annotations,
         mediaItemFilterName,
      })
   );

   const response = await fetch(`/api/2.0/user/media/images/edits?csrf=${CSRF.get()}`, {
      method: 'POST',
      body: formData,
   });

   // We want to throw on a bad response so the mutation's onError handler is called.
   if (!response.ok) {
      throw response;
   }

   return response.json();
}
