import { ToolboxPanel } from 'markerjs2';
import stamps from './customStamps';

export class StampTypePanel extends ToolboxPanel {
   public static readonly typeName = 'StampTypePanel';
   public readonly title: string;
   public readonly icon: string;
   public stampType: string;
   public onStampSelected: (stampType: string) => void;

   private readonly stampTypeButtons: Map<string, HTMLDivElement> = new Map();

   constructor(title: string, icon: string, stampType = Object.keys(stamps)[0]) {
      super(title, icon);
      this.title = title;
      this.icon = icon;
      this.stampType = stampType;
      this.onStampSelected = () => {}; // Default no-op function
   }

   public getUi(): HTMLDivElement {
      const container = this.createContainer();
      this.createStampButtons(container);
      this.setCurrentStampType(this.stampType);
      return container;
   }

   private createContainer(): HTMLDivElement {
      const container = document.createElement('div');
      container.style.display = 'flex';
      container.style.flexDirection = 'row';
      container.style.alignItems = 'center';
      container.style.justifyContent = 'center';
      return container;
   }

   private createStampButtons(container: HTMLDivElement): void {
      Object.entries(stamps).forEach(([key, stamp]) => {
         if (stamp.name === 'stampMark') return; // skip the stampMark icon
         const button = this.createStampButton(key, stamp.name, stamp.icon);
         container.appendChild(button);
         this.stampTypeButtons.set(stamp.name, button);
      });
   }

   private createStampButton(key: string, stampName: string, stampIcon: string): HTMLDivElement {
      const button = document.createElement('div');
      button.classList.add(
         '__markerjs2__0_toolbox_button',
         `__markerjs2__0_toolbox-button_${key.toLowerCase()}`
      );
      button.setAttribute('role', 'button');
      button.setAttribute('aria-label', stampName);
      button.setAttribute('title', stampName);
      button.setAttribute('data-action', `${stampName.toLowerCase()}-panel`);

      button.addEventListener('click', e => this.handleStampPanelButtonClick(e));

      const svgElement = this.createSvgElement(stampIcon);
      button.appendChild(svgElement);

      this.styleButton(button);

      return button;
   }

   private createSvgElement(stampIcon: string): SVGElement {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = stampIcon;
      const svgElement = tempDiv.firstElementChild as SVGElement;
      svgElement.classList.add('__markerjs2__0_toolbox_button_icon');
      svgElement.setAttribute('width', '24');
      svgElement.setAttribute('height', '24');
      svgElement.style.transform = 'scale(1.5)';
      svgElement.style.overflow = 'visible';
      return svgElement;
   }

   private styleButton(button: HTMLDivElement): void {
      Object.assign(button.style, {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         margin: '0 4px',
         fill: 'white',
         border: '2px solid transparent',
      });
   }

   private handleStampPanelButtonClick(e: MouseEvent): void {
      const clickedElement = e.currentTarget as HTMLDivElement;
      const stampType = clickedElement.getAttribute('title');

      if (stampType) {
         this.setCurrentStampType(stampType);
         this.onStampSelected(stampType);
      } else {
         console.warn('No valid stamp type found.');
      }
   }

   private setCurrentStampType(stampType: string): void {
      this.stampType = stampType;

      this.stampTypeButtons.forEach((button, key) => {
         button.style.borderColor =
            key === stampType ? this.uiStyleSettings.toolboxAccentColor : 'transparent';
      });
   }
}
