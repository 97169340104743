import { CropAreaState } from 'cropro';
import { MarkerAreaState } from 'markerjs2';

export type ImageInfoImage = {
   id: number;
   guid: string;
   mini: string;
   thumbnail: string;
   '140x105': string;
   '200x150': string;
   standard: string;
   '440x330': string;
   medium: string;
   large: string;
   huge: string;
   original: string;
};

export type CommonMarkupState<Data> = {
   type: 'NODE_MARKUP' | 'MARKER_JS' | 'CROPRO';
   version: string;
   data: Data;
};

export type NodeMarkupCropData = {
   crop: {
      x: number;
      y: number;
      width: number;
      height: number;
   };
};

export type NodeMarkupColor =
   | 'red'
   | 'orange'
   | 'yellow'
   | 'green'
   | 'lightBlue'
   | 'blue'
   | 'darkBlue'
   | 'violet'
   | 'pink'
   | 'black';

export type NodeMarkupCircleMarker = {
   shape: 'circle';
   x: number;
   y: number;
   radius: number;
   color: NodeMarkupColor;
};

export type NodeMarkupRectangleMarker = {
   shape: 'rectangle';
   x: number;
   y: number;
   width: number;
   height: number;
   color: NodeMarkupColor;
};

export type NodeMarkupLineMarker = {
   shape: 'line' | 'arrow' | 'gap';
   xStart: number;
   yStart: number;
   xEnd: number;
   yEnd: number;
   color: NodeMarkupColor;
};

export type NodeMarkupMarkersData = Array<
   NodeMarkupCircleMarker | NodeMarkupRectangleMarker | NodeMarkupLineMarker
>;

export type MarkupState = {
   edits?: CommonMarkupState<NodeMarkupCropData | CropAreaState>;
   annotations?: CommonMarkupState<NodeMarkupMarkersData | MarkerAreaState>;
};

export type ImageInfoBase = {
   image: ImageInfoImage;
   srcid?: number;
   width: number;
   height: number;
   ratio: 'FOUR_THREE' | 'ONE_ONE' | 'VARIABLE';
   markup?: string;
   markupState?: MarkupState;
   exif?: {
      FileName?: string;
      [field: string]: any;
   };
   encoding: 'png' | 'jpeg';
   filterInfo?: {
      ratio?: 'FOUR_THREE' | 'ONE_ONE';
      height?: string;
      width?: string;
   };
   suggestedCrop?: NodeMarkupCropData['crop'];
};

export type ImageInfo = ImageInfoBase & {
   srcImageInfo: ImageInfoBase;
};

export function isNodeMarkupEditsState(
   state: CommonMarkupState<any>
): state is CommonMarkupState<NodeMarkupCropData> {
   return state.type === 'NODE_MARKUP' && Object.prototype.hasOwnProperty.call(state.data, 'crop');
}

export function isCroproEditsState(
   state: CommonMarkupState<any>
): state is CommonMarkupState<CropAreaState> {
   return state.type === 'CROPRO';
}

export function isNodeMarkupMarkersState(
   state: CommonMarkupState<any>
): state is CommonMarkupState<NodeMarkupMarkersData> {
   return state.type === 'NODE_MARKUP' && Array.isArray(state.data);
}

export function isMarkerjsMarkersState(
   state: CommonMarkupState<any>
): state is CommonMarkupState<MarkerAreaState> {
   return state.type === 'MARKER_JS';
}
