import { CropAreaState } from 'cropro';
import { ImageInfo, isCroproEditsState, isNodeMarkupEditsState } from './ImageInfo';

export function toCropAreaState(imageInfo: ImageInfo): CropAreaState | null {
   const sourceImageInfo = imageInfo.srcid ? imageInfo.srcImageInfo : imageInfo;
   const edits = imageInfo.markupState?.edits;
   if (!edits) {
      return null;
   }

   if (isNodeMarkupEditsState(edits)) {
      const nodeMarkupCrop = edits.data?.crop;

      return {
         flippedHorizontally: false,
         flippedVertically: false,
         rotationAngle: 0,

         height: sourceImageInfo.height,
         width: sourceImageInfo.width,
         cropRect: {
            // The CROPRO editor expects a 20px offset to the crop coordinates
            x: nodeMarkupCrop.x + 20,
            y: nodeMarkupCrop.y + 20,
            width: nodeMarkupCrop.width,
            height: nodeMarkupCrop.height,
         },
      };
   } else if (isCroproEditsState(edits)) {
      return edits.data;
   }

   // Edits type isn't implemented!
   return null;
}

export function convertSuggestedCropToCropAreaState(imageInfo: ImageInfo): CropAreaState | null {
   const sourceImageInfo = imageInfo.srcImageInfo || imageInfo;
   const suggestedCrop = sourceImageInfo.suggestedCrop;

   if (suggestedCrop) {
      return {
         flippedHorizontally: false,
         flippedVertically: false,
         rotationAngle: 0,

         height: sourceImageInfo.height,
         width: sourceImageInfo.width,
         cropRect: {
            height: suggestedCrop.height,
            width: suggestedCrop.width,
            // The CROPRO editor expects a 20px offset to the crop coordinates
            x: suggestedCrop.x + 20,
            y: suggestedCrop.y + 20,
         },
      };
   }

   return null;
}
