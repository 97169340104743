import * as React from 'react';
import { apiClient } from 'Shared/api_client';
import { useQuery } from 'react-query';
import { ImageInfo } from './ImageInfo';

export function useImageInfo(imageId: number | null, mediaItemFilterName?: string) {
   const getImageInfo = React.useCallback(
      async (imageId: number | null, mediaItemFilterName?: string): Promise<ImageInfo> => {
         if (!imageId) {
            return null;
         }
         const response = await apiClient.get(`media/images/${imageId}`, {
            params: { mediaItemFilterName },
         });
         return response.data;
      },
      []
   );

   return useQuery(
      ['markup', 'imageInfo', imageId, mediaItemFilterName],
      () => getImageInfo(imageId, mediaItemFilterName),
      {
         staleTime: Infinity,
         refetchOnMount: false,
         refetchOnWindowFocus: false,
      }
   );
}
