import * as React from 'react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { useToast } from '@chakra-ui/react';
import { MarkersEditor } from './MarkersEditor';
import { ImageEditor } from './ImageEditor';
import { useImageInfo } from './useImageInfo';
import { _js } from '@ifixit/localize';
import { CommonMarkupState } from './ImageInfo';
import { uploadEditedImage } from './uploadEditedImage';
import { useMutation } from 'react-query';
import MediaLibrary from 'Shared/FrameModules/MediaLibrary/library';
import { MediaTarget } from 'Shared/FrameModules/MediaLibrary/target';
import { MarkupImage } from './MarkupImage';

type MediaImage = {
   getID(): number;
   setDataPromise(dataPromise: typeof globalThis.Future): void;
   data: {
      filter_state(): string | boolean;
   };
   getContext(): any;
};

type EventDetails = {
   mediaImage: MediaImage;
};

interface MarkupComponentProps {
   markerColors?: string[];
   defaultMarkerColor?: string;
}

function MarkupComponent({ markerColors, defaultMarkerColor }: MarkupComponentProps) {
   const [mediaImage, setMediaImage] = React.useState<MediaImage | null>(null);
   const [mode, setMode] = React.useState<'markers' | 'edit' | null>(null);
   const [sourceImageElement, setSourceImageElement] = React.useState<HTMLImageElement | null>(
      null
   );

   const imageId = mediaImage?.getID() || null;
   const mediaItemFilterName = getMediaItemFilterName(mediaImage);

   function reset() {
      setMediaImage(null);
      setMode(null);
      setSourceImageElement(null);
   }

   const { data, error } = useImageInfo(imageId, mediaItemFilterName);
   const sourceImageInfo = data?.srcid ? data.srcImageInfo : data;

   const openMarkersEditor = React.useCallback(
      (event: CustomEvent<EventDetails>) => {
         setMediaImage(event.detail.mediaImage);
         setMode('markers');
      },
      [setMediaImage, setMode]
   );

   const openImageEditor = React.useCallback(
      (event: CustomEvent<EventDetails>) => {
         setMediaImage(event.detail.mediaImage);
         setMode('edit');
      },
      [setMediaImage, setMode]
   );

   React.useEffect(() => {
      document.addEventListener('openMarkersEditor', openMarkersEditor);
      document.addEventListener('openImageEditor', openImageEditor);

      return () => {
         document.removeEventListener('openMarkersEditor', openMarkersEditor);
         document.removeEventListener('openImageEditor', openImageEditor);
      };
   }, [openMarkersEditor, openImageEditor]);

   const isLoading = imageId && !error && (!data || !sourceImageElement);

   const toast = useToast();
   const toastIdRef = React.useRef<string | number>();
   React.useEffect(() => {
      if (isLoading && !toast.isActive('markup-loading-toast')) {
         toast.close(toastIdRef.current);
         toastIdRef.current = toast({
            id: 'markup-loading-toast',
            title: _js('Loading image editor...'),
            status: 'loading',
            position: 'top',
            duration: null,
         });
         return;
      }
      if (error) {
         toast.update(toastIdRef.current, {
            title: _js('An error occurred!'),
            description: _js(
               'Unable to load the image markup editor. Please refresh the page and try again.'
            ),
            status: 'error',
            position: 'top',
            duration: null,
            isClosable: true,
         });
         return;
      }
      if (sourceImageElement) {
         toast.close(toastIdRef.current);
         toastIdRef.current = null;
      }
   }, [toast, isLoading, sourceImageElement, error]);

   const mutation = useMutation({
      mutationFn({ dataUrl, state }: { dataUrl: string; state: CommonMarkupState<any> }) {
         // upload the edited image
         return uploadEditedImage(dataUrl, state, data, mediaItemFilterName);
      },
      onMutate() {
         const mediaImageDataPromise = new globalThis.Future();
         mediaImage.setDataPromise(mediaImageDataPromise);
         return mediaImageDataPromise;
      },
      onSuccess(imageData: any, _, mediaImageDataPromise: typeof globalThis.Future) {
         if (!imageData.filter_state) {
            // Markup changes don't affect the filter state, so we match the previous value.
            imageData.filter_state = mediaImage.data.filter_state();
         }
         mediaImageDataPromise.resolve(imageData);
      },
      onError(response: Response, _, mediaImageDataPromise: typeof globalThis.Future) {
         let message = _js('Unable to upload the edited image. Please try again.');

         // It's unlikely we'll hit these error states because the user shouldn't be able to access
         // the page that renders this component. We're handling them just in case.
         if (response.status === 401) {
            message = _js('Please log in to edit images.');
         } else if (response.status === 403) {
            message = _js('You are not allowed to edit this image.');
         }

         mediaImageDataPromise.error(message);
      },
      onSettled() {
         reset();
      },
   });

   let Editor: typeof ImageEditor | typeof MarkersEditor;
   if (mode === 'edit') {
      Editor = ImageEditor;
   } else if (mode === 'markers') {
      if (data?.filterInfo?.ratio && data.ratio !== data.filterInfo.ratio) {
         // We can't edit markers until the image is at the required aspect ratio, so we force the
         // user to edit the image first.
         Editor = ImageEditor;
      } else {
         Editor = MarkersEditor;
      }
   }

   return (
      <>
         <MarkupImage
            imageInfo={sourceImageInfo}
            imageSrc={sourceImageInfo?.image?.original}
            onLoad={image => setSourceImageElement(image)}
         />
         {Editor && data && sourceImageElement && (
            <Editor
               imageInfo={data}
               sourceImage={sourceImageElement}
               onClose={reset}
               onRender={async (dataUrl: string, state: CommonMarkupState<any>) =>
                  mutation.mutate({ dataUrl, state })
               }
               // Pass MarkersEditor specific props
               {...(Editor === MarkersEditor ? { markerColors, defaultMarkerColor } : {})}
            />
         )}
      </>
   );
}

initializeChakraComponent('MarkupComponent', MarkupComponent);

function getMediaItemFilterName(mediaImage?: MediaImage): string | null {
   let itemContext = mediaImage?.getContext();
   let mediaTarget = null;
   if (itemContext === MediaLibrary) {
      mediaTarget = MediaLibrary.mediaTarget;
   } else if (globalThis.instanceOf(itemContext, MediaTarget)) {
      mediaTarget = itemContext;
   }
   return mediaTarget?.getMediaFilterName() || null;
}
