import {
   Settings,
   SvgHelper,
   IPoint,
   ToolboxPanel,
   ColorPickerPanel,
   LineWidthPanel,
   RectangularBoxMarkerBase,
   RectangleMarkerState,
   MarkerBaseState,
} from 'markerjs2';
import stamps from './customStamps';
import { StampTypePanel } from './StampTypePanel';

export interface StampMarkerState extends RectangleMarkerState {
   stampType: string;
}

export class StampMarker extends RectangularBoxMarkerBase {
   public static readonly typeName = 'StampMarker';
   public static readonly title = 'Stamp';
   public static readonly icon = stamps.stampMark.icon;

   protected defaultSize: IPoint = { x: 48, y: 48 };
   private defaultIconSize = 16;

   private strokeColor: string;
   private strokeWidth: number;
   private stampTypeSelection: string;
   private selectedStamp: SVGElement | null = null;
   private rectangleElement: SVGRectElement;

   private readonly colorPickerPanel: ColorPickerPanel;
   private readonly lineWidthPanel: LineWidthPanel;
   private readonly stampPanel: StampTypePanel;

   constructor(container: SVGGElement, overlayContainer: HTMLDivElement, settings: Settings) {
      super(container, overlayContainer, settings);

      this.strokeColor = settings.defaultColor;
      this.strokeWidth = settings.defaultStrokeWidth;
      this.stampTypeSelection = Object.keys(stamps)[0];

      this.colorPickerPanel = new ColorPickerPanel(
         'Color',
         settings.defaultColorSet,
         this.strokeColor
      );
      this.lineWidthPanel = new LineWidthPanel(
         'Line Width',
         settings.defaultStrokeWidths,
         this.strokeWidth
      );
      this.stampPanel = new StampTypePanel('Stamp Type', StampMarker.icon, this.stampTypeSelection);

      this.initializeEventHandlers();
   }

   private initializeEventHandlers(): void {
      this.colorPickerPanel.onColorChanged = this.handleColorChange.bind(this);
      this.lineWidthPanel.onWidthChanged = this.handleWidthChange.bind(this);
      this.stampPanel.onStampSelected = this.handleStampSelection.bind(this);
   }

   private handleColorChange(newColor: string): void {
      this.strokeColor = newColor;
      this.updateStrokeColor();
   }

   private handleWidthChange(newWidth: number): void {
      this.strokeWidth = newWidth;
      this.updateStampPosition();
   }

   private handleStampSelection(stampType: string): void {
      this.stampTypeSelection = stampType;
      this.setStampPath();
      this.updateStampPosition();
   }

   protected createVisual(): void {
      this.rectangleElement = this.createRectangleElement();
      const translate = SvgHelper.createTransform();
      this.setStampPath();

      const markerGroup = SvgHelper.createGroup();
      markerGroup.transform.baseVal.appendItem(translate);

      markerGroup.appendChild(this.rectangleElement);
      if (this.selectedStamp) {
         markerGroup.appendChild(this.selectedStamp);
      }

      this.visual = markerGroup;
      this.addMarkerVisualToContainer(this.visual);
      this.updateStampPosition();
   }

   private createRectangleElement(): SVGRectElement {
      return SvgHelper.createRect(this.width, this.height, [
         ['stroke', 'transparent'],
         ['fill', 'transparent'],
         ['stroke-width', this.strokeWidth.toString()],
         ['pointer-events', 'all'],
      ]);
   }

   private setStampPath(): void {
      if (this.selectedStamp && this.visual) {
         this.visual.removeChild(this.selectedStamp);
      }
      if (this.stampTypeSelection) {
         this.selectedStamp = stamps[this.stampTypeSelection].path(this.strokeColor, 1);
         if (this.selectedStamp) {
            SvgHelper.setAttributes(this.selectedStamp, [['fill', this.strokeColor]]);
         }
         this.visual?.appendChild(this.selectedStamp);
         this.updateStampPosition();
      }
   }

   private updateStampPosition(): void {
      if (!this.selectedStamp) return;

      const scaleX = this.width / this.defaultIconSize;
      const scaleY = this.height / this.defaultIconSize;

      SvgHelper.setAttributes(this.selectedStamp, [['transform', `scale(${scaleX}, ${scaleY})`]]);
      this.updateStrokeWidth(Math.min(scaleX, scaleY));
   }

   protected updateStrokeColor(): void {
      if (!this.selectedStamp) return;
      SvgHelper.setAttributes(this.selectedStamp, [
         ['stroke', this.strokeColor],
         ['fill', this.strokeColor],
      ]);
   }

   protected updateStrokeWidth(scaleFactor: number = 1): void {
      if (!this.selectedStamp) return;
      const adjustedWidth = this.strokeWidth / scaleFactor;
      SvgHelper.setAttributes(this.selectedStamp, [['stroke-width', adjustedWidth.toString()]]);
   }

   protected setSize(): void {
      super.setSize();
      if (!this.rectangleElement) return;
      SvgHelper.setAttributes(this.rectangleElement, [
         ['width', this.width.toString()],
         ['height', this.height.toString()],
      ]);
      this.updateStampPosition();
   }

   protected moveVisual(point: IPoint): void {
      if (!this.visual) return;
      const translate = this.visual.transform.baseVal.getItem(0);
      translate.setTranslate(point.x, point.y);
      this.visual.transform.baseVal.replaceItem(translate, 0);
   }

   public pointerDown(point: IPoint, target?: EventTarget): void {
      super.pointerDown(point, target);
      if (this.state === 'new') {
         this.initializeNewMarker(point);
      }
   }

   private initializeNewMarker(point: IPoint): void {
      // start with a 1x1 marker to work with resizes
      this.width = 1;
      this.height = 1;
      this.createVisual();
      this.setSize();
      this._state = 'creating';
   }

   public pointerUp(point: IPoint): void {
      super.pointerUp(point);
      this.setSize();
   }

   public get toolboxPanels(): ToolboxPanel[] {
      return [this.colorPickerPanel, this.lineWidthPanel, this.stampPanel];
   }

   public ownsTarget(el: EventTarget): boolean {
      return (
         super.ownsTarget(el) ||
         this.visual?.contains(el as Node) ||
         this.rectangleElement?.contains(el as Node) ||
         this.selectedStamp?.contains(el as Node)
      );
   }

   public getState(): StampMarkerState {
      const result: StampMarkerState = {
         ...super.getState(),
         stampType: this.stampTypeSelection,
         strokeColor: this.strokeColor,
         strokeWidth: this.strokeWidth,
         fillColor: 'transparent',
         strokeDasharray: 'none',
         opacity: 1,
         typeName: StampMarker.typeName,
      };
      return result;
   }

   public restoreState(state: MarkerBaseState): void {
      const smState = state as StampMarkerState;
      this.strokeColor = smState.strokeColor;
      this.strokeWidth = smState.strokeWidth;
      this.stampTypeSelection = smState.stampType;
      this.height = smState.height;
      this.width = smState.width;
      this.createVisual();
      super.restoreState(state);
   }
}
