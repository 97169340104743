import * as React from 'react';
import { CropArea, CropAreaState } from 'cropro';

export function useEditedImage(
   sourceImage: HTMLImageElement,
   cropAreaState: CropAreaState
): string | undefined {
   const [editedImageSrc, setEditedImageSrc] = React.useState<string>();

   React.useEffect(() => {
      if (!sourceImage) {
         if (editedImageSrc) {
            setEditedImageSrc(undefined);
         }
         return;
      }

      if (cropAreaState) {
         const cropArea = new CropArea(sourceImage);
         cropArea.renderAtNaturalSize = true;
         cropArea.addRenderEventListener(imgURL => setEditedImageSrc(imgURL));
         cropArea.renderState(cropAreaState);
         return;
      } else {
         setEditedImageSrc(undefined);
      }
   }, [sourceImage, cropAreaState]);

   return editedImageSrc;
}
