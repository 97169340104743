import {
   CurveMarker,
   ArrowTypePanel,
   ArrowType,
   Settings,
   SvgHelper,
   IPoint,
   ToolboxPanel,
   MarkerBaseState,
   CurveMarkerState,
   ArrowMarkerState,
} from 'markerjs2';

export type CurvedArrowMarkerState = CurveMarkerState & ArrowMarkerState;

/**
 * This marker is a combination of the native CurveMarker and ArrowMarker. We extend the CurveMarker
 * and copy the contents of the ArrowMarker with some modifications.
 *
 * See https://github.com/ailon/markerjs2/blob/8b0c599aed812135424e62a168894b034d68b40b/src/markers/arrow-marker/ArrowMarker.ts
 */
export class CurvedArrowMarker extends CurveMarker {
   public static typeName = 'CurvedArrowMarker';
   public static title = 'Curved arrow marker';

   private arrow1: SVGPolygonElement;
   private arrow2: SVGPolygonElement;

   private arrowType: ArrowType = 'end';

   private arrowBaseHeight = 10;
   private arrowBaseWidth = 10;

   /**
    * Toolbox panel for arrow type selection.
    */
   protected arrowTypePanel: ArrowTypePanel;

   /**
    * Creates a new marker.
    *
    * @param container - SVG container to hold marker's visual.
    * @param overlayContainer - overlay HTML container to hold additional overlay elements while editing.
    * @param settings - settings object containing default markers settings.
    */
   constructor(container: SVGGElement, overlayContainer: HTMLDivElement, settings: Settings) {
      super(container, overlayContainer, settings);

      this.getArrowPoints = this.getArrowPoints.bind(this);
      this.setArrowType = this.setArrowType.bind(this);

      this.arrowTypePanel = new ArrowTypePanel('Arrow type', 'end');
      this.arrowTypePanel.onArrowTypeChanged = this.setArrowType;
   }

   /**
    * Returns true if passed SVG element belongs to the marker. False otherwise.
    *
    * @param el - target element.
    */
   public ownsTarget(el: EventTarget): boolean {
      if (super.ownsTarget(el) || el === this.arrow1 || el === this.arrow2) {
         return true;
      } else {
         return false;
      }
   }

   private getArrowPoints(offsetX: number, offsetY: number): string {
      const width = this.arrowBaseWidth + this.strokeWidth * 2;
      const height = this.arrowBaseHeight + this.strokeWidth * 2;
      return `${offsetX - width / 2},${offsetY + height / 2} ${offsetX},${offsetY - height / 2} ${
         offsetX + width / 2
      },${offsetY + height / 2}`;
   }

   private createTips() {
      this.arrow1 = SvgHelper.createPolygon(this.getArrowPoints(this.x1, this.y1), [
         ['fill', this.strokeColor],
      ]);
      this.arrow1.transform.baseVal.appendItem(SvgHelper.createTransform());
      this.visual.appendChild(this.arrow1);

      this.arrow2 = SvgHelper.createPolygon(this.getArrowPoints(this.x2, this.y2), [
         ['fill', this.strokeColor],
      ]);
      this.arrow2.transform.baseVal.appendItem(SvgHelper.createTransform());
      this.visual.appendChild(this.arrow2);
   }

   /**
    * Handles pointer (mouse, touch, stylus, etc.) down event.
    *
    * @param point - event coordinates.
    * @param target - direct event target element.
    */
   public pointerDown(point: IPoint, target?: EventTarget): void {
      super.pointerDown(point, target);
      if (this.state === 'creating') {
         this.createTips();
      }
   }

   /**
    * Adjusts marker visual after manipulation.
    *
    * The ArrowMarker uses the angle of (x1,y1) to (x2,y2) to calculate the angle of the arrow tip.
    * For our custom curved arrow marker, we need the third grab point that exists on the
    * CurveMarker. The angle on one end is (x1,y1) to (curveX, curveY), and the angle of the other
    * end is (x2,y2) to (curveX, curveY).
    *
    * See adjustVisual() of the ArrowMarker for reference:
    * https://github.com/ailon/markerjs2/blob/8b0c599aed812135424e62a168894b034d68b40b/src/markers/arrow-marker/ArrowMarker.ts#L112
    */
   protected adjustVisual(): void {
      super.adjustVisual();

      if (!this.arrow1 || !this.arrow2) {
         return;
      }

      this.arrow1.style.display =
         this.arrowType === 'both' || this.arrowType === 'start' ? '' : 'none';
      this.arrow2.style.display =
         this.arrowType === 'both' || this.arrowType === 'end' ? '' : 'none';

      SvgHelper.setAttributes(this.arrow1, [
         ['points', this.getArrowPoints(this.x1, this.y1)],
         ['fill', this.strokeColor],
      ]);
      SvgHelper.setAttributes(this.arrow2, [
         ['points', this.getArrowPoints(this.x2, this.y2)],
         ['fill', this.strokeColor],
      ]);

      const lineAngle1 = this.getAngleToCurvePoint(this.x1, this.y1);
      const lineAngle2 = this.getAngleToCurvePoint(this.x2, this.y2);

      this.transformArrowTip(this.arrow1, lineAngle1, this.x1, this.y1);
      this.transformArrowTip(this.arrow2, lineAngle2, this.x2, this.y2);
   }

   private getAngleToCurvePoint(x: number, y: number): number {
      const { curveX, curveY } = this.getState();

      if (Math.abs(x - curveX) <= 0.1) {
         return 0;
      }

      return (Math.atan((curveY - y) / (curveX - x)) * 180) / Math.PI + 90 * Math.sign(x - curveX);
   }

   private transformArrowTip(arrow: SVGPolygonElement, angle: number, x: number, y: number): void {
      const transform = arrow.transform.baseVal.getItem(0);
      transform.setRotate(angle, x, y);
      arrow.transform.baseVal.replaceItem(transform, 0);
   }

   private setArrowType(arrowType: ArrowType) {
      this.arrowType = arrowType;
      this.adjustVisual();
      this.stateChanged();
   }

   /**
    * Returns the list of toolbox panels for this marker type.
    */
   public get toolboxPanels(): ToolboxPanel[] {
      return [this.strokePanel, this.strokeWidthPanel, this.arrowTypePanel];
   }

   /**
    * Returns current marker state that can be restored in the future.
    */
   public getState(): CurvedArrowMarkerState {
      const result: CurvedArrowMarkerState = Object.assign(
         {
            arrowType: this.arrowType,
         },
         super.getState()
      );
      result.typeName = CurvedArrowMarker.typeName;

      return result;
   }

   /**
    * Restores previously saved marker state.
    */
   public restoreState(state: MarkerBaseState): void {
      super.restoreState(state);

      const amState = state as CurvedArrowMarkerState;
      this.arrowType = amState.arrowType;

      this.createTips();
      this.adjustVisual();
   }
}
