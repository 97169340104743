import * as React from 'react';
import { Image } from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { ImageInfoBase } from './ImageInfo';

export type MarkupImageProps = {
   imageInfo?: ImageInfoBase;
   imageSrc?: string;
   onLoad: (image: HTMLImageElement) => void;
};

export function MarkupImage({ imageInfo, imageSrc, onLoad }: MarkupImageProps) {
   const [imageElement, setImageElement] = React.useState<HTMLImageElement | null>(null);

   const imageRef = React.useCallback(node => {
      setImageElement(node);
   }, []);

   return (
      <>
         {imageInfo && imageSrc && (
            <Image
               // The source image needs to be styled at its natural size for the
               // "renderAtNaturalSize" feature of CROPRO and marker.js to work correctly.
               w={`${imageInfo.width}px`}
               h={`${imageInfo.height}px`}
               // CROPRO and marker.js require that the image is loaded and visible on the page
               // before instantiating their editors. We need to load the full size edited image,
               // but we don't want the user to see it, so we position it way off the screen as a
               // workaround.
               position="absolute"
               top="-100000px"
               // Setting the crossOrigin would only be necessary if the image src is from a
               // a different domain. Adding it here in case our image urls are different in xprem.
               crossOrigin="anonymous"
               src={imageSrc}
               alt={imageInfo.exif?.FileName || _js('Source image')}
               ref={imageRef}
               onLoad={() => onLoad(imageElement)}
            />
         )}
      </>
   );
}
