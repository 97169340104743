import * as React from 'react';
import { CommonMarkupState, ImageInfo } from './ImageInfo';
import { MarkerArea, MarkerAreaState, Activator } from 'markerjs2';
import { toMarkerAreaState } from './toMarkerAreaState';
import { editorZIndex } from './shareStyles';
import { MarkupImage } from './MarkupImage';
import { useEditedImage } from './useEditedImage';
import { usePaddedImageDetails } from './usePaddedImageDetails';
import { toCropAreaState } from './toCropAreaState';
import { getDefaultStrokeWidth } from './getDefaultStrokeWidth';
import { round } from 'lodash';
import { _js } from '@ifixit/localize';
import { CurvedArrowMarker } from './CurvedArrowMarker';
import { StampMarker } from './StampMarker';

// Enable marker.js license. Removes the logo from the editor.
// This key is already exposed since we have to set this on the frontend. It's low risk so we
// hardcode it for simplicity.
Activator.addKey('MJS2-F803-M284-3658');

export type MarkersEditorProps = {
   imageInfo: ImageInfo;
   sourceImage: HTMLImageElement | null;
   onClose: () => void;
   onRender: (dataUrl: string, state: CommonMarkupState<MarkerAreaState>) => void;
   markerColors?: string[];
   defaultMarkerColor?: string;
};

export function MarkersEditor({
   imageInfo,
   sourceImage,
   onClose,
   onRender,
   markerColors,
   defaultMarkerColor,
}: MarkersEditorProps) {
   const { shouldUsePaddedImage, paddedImageInfo, paddedImageSrc, paddedCropAreaState } =
      usePaddedImageDetails(imageInfo, sourceImage);

   const [paddedImage, setPaddedImage] = React.useState<HTMLImageElement | null>(null);

   const imageElementToEdit = shouldUsePaddedImage ? paddedImage : sourceImage;
   const cropAreaState = shouldUsePaddedImage ? paddedCropAreaState : toCropAreaState(imageInfo);
   let editedImageSrc = useEditedImage(imageElementToEdit, cropAreaState);

   const [editedImageElement, setEditedImageElement] = React.useState<HTMLImageElement | null>(
      null
   );

   // If padding isn't required and there's no previous edit state saved, we can just use the
   // original source image
   const imageElement = !shouldUsePaddedImage && !cropAreaState ? sourceImage : editedImageElement;

   React.useEffect(() => {
      if (!imageElement) {
         return;
      }

      const markerArea = new MarkerArea(imageElement);

      markerArea.renderAtNaturalSize = true;
      if (imageInfo.encoding === 'jpeg') {
         markerArea.renderImageType = 'image/jpeg';
         markerArea.renderImageQuality = 1;
      }

      markerArea.availableMarkerTypes = [
         'FrameMarker',
         'EllipseFrameMarker',
         'ArrowMarker',
         'LineMarker',
         'MeasurementMarker',
         CurvedArrowMarker,
         'TextMarker',
         'CalloutMarker',
         'HighlightMarker',
         StampMarker,
      ];
      markerArea.settings.displayMode = 'popup';

      markerArea.uiStyleSettings.canvasBackgroundColor = '#a1a1a1';

      if (markerColors && markerColors.length > 0) {
         markerArea.settings.defaultColorSet = markerColors;

         const defaultColor = defaultMarkerColor || markerColors[0];
         markerArea.settings.defaultColor = defaultColor;
         markerArea.settings.defaultFillColor = defaultColor;
      }

      const defaultStrokeWidth = getDefaultStrokeWidth();
      markerArea.settings.defaultStrokeWidth = defaultStrokeWidth;
      markerArea.settings.defaultStrokeWidths = [
         round(defaultStrokeWidth * 0.5, 1),
         defaultStrokeWidth,
         round(defaultStrokeWidth * 1.5, 1),
      ];

      // grabbing the default font family from the body element to make sure it matches the rest of the page
      const defaultFontFamily = getComputedStyle(document.body).fontFamily;
      // this sets the available fonts for the text marker
      markerArea.settings.defaultFontFamilies = [defaultFontFamily];
      markerArea.settings.defaultFontFamily = defaultFontFamily;

      // Only allow solid line styles
      markerArea.settings.defaultStrokeDasharrays = [''];
      markerArea.settings.defaultStrokeDasharray = '';

      markerArea.settings.defaultText = _js('Text');

      // The media manager modal has a large z-index, so we need an even bigger one when opening
      // this editor from an image in the media manager :/
      markerArea.styles.settings.zIndex = editorZIndex;

      markerArea.styles.settings.redoButtonVisible = true;

      markerArea.addEventListener('close', onClose);

      let markersStateChanged = false;
      markerArea.addEventListener('markercreate', () => (markersStateChanged = true));
      markerArea.addEventListener('markerdelete', () => (markersStateChanged = true));
      markerArea.addEventListener('markerchange', () => (markersStateChanged = true));

      markerArea.addEventListener('render', event => {
         if (!markersStateChanged) {
            onClose();
            return;
         }

         const data = {
            ...event.state,
            markers: event.state.markers.map(marker => {
               // Save ratio of chosen stroke width compared to default so we can convert the stroke
               // width when the editor is opened on a different size screen.
               if (marker['strokeWidth'] !== undefined) {
                  marker['strokeWidthRatioToDefault'] = round(
                     marker['strokeWidth'] / defaultStrokeWidth,
                     1
                  );
               }

               // The fontFamily might include quotes which will not be json decoded correctly in
               // php so we encode the string.
               if (marker['fontFamily']) {
                  marker['fontFamily'] = encodeURI(marker['fontFamily']);
               }

               return marker;
            }),
         };

         onRender(event.dataUrl, {
            type: 'MARKER_JS',
            version: '2',
            data,
         });
      });

      markerArea.show();
      // Add a style tag to the SVG to ensure our default font is used in the text markers. This section needs to stay below markerArea.show().
      // For more info, see markerjs2's docs on adding custom fonts: https://markerjs.com/docs/custom-fonts/
      const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
      style.setAttribute('type', 'text/css');
      style.innerHTML = `text{font-family: ${defaultFontFamily};}`;
      markerArea.addDefs(style);

      const markerAreaState = toMarkerAreaState(imageInfo);
      if (markerAreaState) {
         markerArea.restoreState(markerAreaState);
      }

      return () => {
         markerArea.close();
      };
   }, [imageElement, imageInfo, onClose, onRender]);

   return (
      <>
         <MarkupImage
            imageInfo={paddedImageInfo}
            imageSrc={paddedImageSrc}
            onLoad={image => setPaddedImage(image)}
         />
         <MarkupImage
            imageInfo={imageInfo}
            imageSrc={editedImageSrc}
            onLoad={image => setEditedImageElement(image)}
         />
      </>
   );
}
