import { round } from 'lodash';

const MODAL_MARGIN = 30;
const TOP_BAR_HEIGHT = 40;
const BOTTOM_BAR_HEIGHT = 100;

/**
 * With our previous markup library (node-markup) we used a 5px stroke width on medium images.
 * An example medium image had a height of 444px and a width of 592px.
 */
const strokeToHeight = 5 / 444;
const strokeToWidth = 5 / 592;

export function getDefaultStrokeWidth(): number {
   const vh = document.documentElement.clientHeight;
   const vw = document.documentElement.clientWidth;

   const maxImageHeight = vh - 2 * MODAL_MARGIN - TOP_BAR_HEIGHT - BOTTOM_BAR_HEIGHT;
   const maxImageWidth = vw - 2 * MODAL_MARGIN;

   if (maxImageWidth < maxImageHeight) {
      // Width is the limiting factor to size the image in the editor
      return round(maxImageWidth * strokeToWidth, 1);
   } else {
      // Height is the limiting factor to size the image in the editor
      return round(maxImageHeight * strokeToHeight, 1);
   }
}
