import * as React from 'react';
import { CropAreaState, IRect } from 'cropro';
import { ImageInfo, ImageInfoBase } from './ImageInfo';
import { toCropAreaState } from './toCropAreaState';

export function usePaddedImageDetails(imageInfo: ImageInfo, sourceImage: HTMLImageElement | null) {
   return React.useMemo(() => {
      const filterRatio = imageInfo.filterInfo?.ratio;
      const sourceImageInfo = imageInfo.srcImageInfo || imageInfo;
      const suggestedCrop = sourceImageInfo.suggestedCrop;

      /**
       * CropAreaState is always relative to the original source image. However, CROPRO does not
       * support a crop that is padded (negative x or y) so we have to generate a padded image
       * on the fly. We need to determine if the source image needed a padding crop so we can
       * correctly adjust and recreate the state for the edited image.
       */
      const shouldUsePaddedImage = suggestedCrop && (suggestedCrop.x < 0 || suggestedCrop.y < 0);

      let paddedImageInfo: ImageInfoBase;
      let paddedImageSrc: string;
      let paddedCropAreaState: CropAreaState;

      if (shouldUsePaddedImage) {
         paddedImageInfo = {
            ...sourceImageInfo,
            height: suggestedCrop.height,
            width: suggestedCrop.width,
         };
         paddedImageSrc = addPaddingToImage(sourceImage, suggestedCrop);

         const cropAreaState = toCropAreaState(imageInfo);
         if (cropAreaState && filterRatio === imageInfo.ratio) {
            const scale = getScaleOfSourceSizeToEditorSize(sourceImageInfo, cropAreaState);

            // Convert the previous state to be relative to the padded image instead of the source
            paddedCropAreaState = {
               ...cropAreaState,
               height: paddedImageInfo.height,
               width: paddedImageInfo.width,
               cropRect: {
                  height: scale * cropAreaState.cropRect.height,
                  width: scale * cropAreaState.cropRect.width,
                  // The CROPRO editor always adds a 20px offset to the coordinates, so we remove that
                  // before adjusting by the scale.
                  x: scale * (cropAreaState.cropRect.x - 20) - suggestedCrop.x + 20,
                  y: scale * (cropAreaState.cropRect.y - 20) - suggestedCrop.y + 20,
               },
            };
         } else {
            // If there's no previous edit state or the state is at the wrong aspect ratio, we can
            // just create one with the crop at the full size of the padded image.
            paddedCropAreaState = {
               flippedHorizontally: false,
               flippedVertically: false,
               rotationAngle: 0,

               height: suggestedCrop.height,
               width: suggestedCrop.width,
               cropRect: {
                  height: suggestedCrop.height,
                  width: suggestedCrop.width,
                  // The CROPRO editor expects a 20px offset to the crop coordinates
                  x: 20,
                  y: 20,
               },
            };
         }
      }

      function convertPaddedStateToSourceState(state: CropAreaState): CropAreaState {
         if (!shouldUsePaddedImage) {
            return state;
         }

         const scale = getScaleOfSourceSizeToEditorSize(sourceImageInfo, state);

         // Convert the state to be relative to the source image instead of the padded image
         return {
            ...state,
            height: sourceImageInfo.height,
            width: sourceImageInfo.width,
            cropRect: {
               height: scale * state.cropRect.height,
               width: scale * state.cropRect.width,
               // The CROPRO editor always adds a 20px offset to the coordinates, so we remove
               // that before adjusting by the scale.
               x: scale * (state.cropRect.x - 20) + suggestedCrop.x + 20,
               y: scale * (state.cropRect.y - 20) + suggestedCrop.y + 20,
            },
         };
      }

      return {
         shouldUsePaddedImage,
         paddedImageInfo,
         paddedImageSrc,
         paddedCropAreaState,
         convertPaddedStateToSourceState,
      };
   }, [imageInfo]);
}

function addPaddingToImage(image: HTMLImageElement, paddedCrop: IRect): string {
   const canvas = document.createElement('canvas');
   canvas.width = paddedCrop.width;
   canvas.height = paddedCrop.height;

   const ctx = canvas.getContext('2d');
   ctx.fillStyle = 'white';
   ctx.fillRect(0, 0, canvas.width, canvas.height);
   ctx.drawImage(image, Math.abs(paddedCrop.x), Math.abs(paddedCrop.y));

   return canvas.toDataURL();
}

function getScaleOfSourceSizeToEditorSize(
   sourceImageInfo: ImageInfoBase,
   cropAreaState: CropAreaState
): number {
   const scaledByHeight = sourceImageInfo.height / cropAreaState.height;
   const scaledByWidth = sourceImageInfo.width / cropAreaState.width;
   const suggestedCrop = sourceImageInfo.suggestedCrop;

   if (suggestedCrop?.x < 0) {
      // Padding was added to the width, so height can be used to compare the sizes.
      return scaledByHeight;
   } else if (suggestedCrop?.y < 0) {
      // Padding was added to the height, so width can be used to compare the sizes.
      return scaledByWidth;
   }

   // There's no padding so the proportion should be the same by height or width. Just pick one.
   return scaledByHeight;
}
